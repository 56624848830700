<template>
  <el-row class="qbit-top-banner">
    <img v-if="width > 1440" src="@/assets/images/recharge/qbit-top-banner-bg.png"
         @click="goToQbit"/>
    <img v-else src="@/assets/images/recharge/qbit-top-banner-sm.png"
         @click="goToQbit"/>
  </el-row>
  <el-card v-loading="$store.getters.isLoading">
    <template #header>
      <el-row justify="space-between">
        <span class="SubHead">个号充值</span>
        <el-link target="_blank"
                 type="primary"
                 :href="`https://www.qbitnetwork.com/#/sign-up?inviteCode=618666`"
                 :underline="false"
        >免费开卡限时优惠
        </el-link>
      </el-row>
    </template>
    <div class="topTip">
      <el-alert
          description=""
          type="info"
          show-icon>
        <template #title>个号充值仅用于充值量子卡产品，请务必保证您的备注信息准确无误。<el-link
            style="display: contents;font-size: 13px;" type="primary"
            :underline="false"
        @click="base.showPdf('document/易诺 x Qbit量子卡 FAQ.pdf')">常见问题</el-link></template>
      </el-alert>
    </div>
    <el-form ref="formRef"
             label-width="100px"
             :model="form"
             :rules="rules">
      <el-form-item label="充值金额" prop="dollar">
        <el-input-number
            size="medium"
            v-model="form.dollar"
            :precision="2"
            :min="0"
            :step="100"
        />
        <span style="margin-left: 5px">美金</span>
        <span style="color: #8c8c8c; font-size: 14px; margin-left: 200px">
                可前往<el-link style="display: contents;" type="primary" href="/recharge_list" :underline="false">
                付款记录
              </el-link>查看钱包充值明细
              </span>
      </el-form-item>
      <el-form-item label="备注" style="width:35%;" prop="accountID">
        <el-input size="small" placeholder="请填写6位数字的账户ID" v-model="form.accountID"></el-input>
      </el-form-item>
      <el-form-item prop="type">
        <el-radio-group v-model="form.type">
          <el-row class="payment-radio"
                  v-for="payment in payments"
                  :key="payment.type">
            <el-col :span="20">
              <el-radio style="width: 100%"
                        border
                        :label="payment.type">
                <el-row :gutter="20" align="middle">
                  <el-col :span="4" class="payment-img">
                    <img v-if="payment.img" :src="payment.img"/>
                    <i v-else-if="payment.icon" :class="'payment-icon ' + payment.icon"/>
                    <span class="payment-label">{{ payment.label }}</span>
                  </el-col>
                  <el-col :span="20" class="describe">
                    <span v-html="payment.describe"></span>
                  </el-col>
                </el-row>
              </el-radio>
            </el-col>
          </el-row>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-row style="line-height: 14px">
          <i style="color: #f56c6c">* </i>温馨提示：
        </el-row>
        <el-row class="Text">
          如支付遇到问题，请将截图上传至
          <el-link type="primary" href="/feedback" :underline="false">问题反馈</el-link>
        </el-row>
      </el-form-item>
      <el-form-item prop="agree">
        <el-row
            align="middle"
            :class="form.agree ? '' : 'agreeTipBorder'">
          <el-checkbox v-model="form.agree" label="" style="margin-right: 5px"></el-checkbox>
          <div class="agreeBox">
            <span class="Text">我已了解：充值的款项只可用于个人指定账户的广告账户消费</span>
          </div>
        </el-row>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :disabled="!form.agree" @click="goPay">前往付款页面</el-button>
      </el-form-item>
    </el-form>
  </el-card>
  <verify
      v-if="'verify' === dialogType"
      :is-special="true"
      :type="form.type"
      :dollar="form.dollar"
      @close="dialogType=''"
      @update="getRechargeInfo()"/>
  <confirm-pay
      v-if="'confirm' === dialogType"
      :dialog-type="dialogContentType"
      :dialog-content="dialogContent"
      :payment-data="form"
      :confirm-data="confirmData"
      @close="dialogType=''"/>

  <form ref="gatewayRef" v-if="form.type==='网关'" action="https://vsp.allinpay.com/apiweb/gateway/pay" method="post">
    <input type="hidden" name="appid" :value="confirmData.appid"/>
    <input type="hidden" name="charset" :value="confirmData.charset"/>
    <input type="hidden" name="cusid" :value="confirmData.cusid"/>
    <input type="hidden" name="goodsinf" :value="confirmData.goodsinf"/>
    <input type="hidden" name="limitpay" :value="confirmData.limitpay"/>
    <input type="hidden" name="notifyurl" :value="confirmData.notifyurl"/>
    <input type="hidden" name="orderid" :value="confirmData.orderid"/>
    <input type="hidden" name="paytype" :value="confirmData.paytype"/>
    <input type="hidden" name="randomstr" :value="confirmData.randomstr"/>
    <input type="hidden" name="returl" :value="confirmData.returl"/>
    <input type="hidden" name="signtype" :value="confirmData.signtype"/>
    <input type="hidden" name="trxamt" :value="confirmData.trxamt"/>
    <input type="hidden" name="validtime" :value="confirmData.validtime"/>
    <input type="hidden" name="sign" :value="confirmData.sign"/>

  </form>
</template>

<script setup>
import {getCurrentInstance, h, onBeforeUnmount, onMounted, ref} from "vue";
import payments from '@/views/finance/qbit/payments'
import api from '@/request/api/finance'
import {ElMessage} from "element-plus";
import Verify from "@/views/finance/base/Verify";
import ConfirmPay from "@/views/finance/qbit/ConfirmPay";
import init from '@/views/finance/qbit/base'
import base from "@/base/filters/base";

const {proxy} = getCurrentInstance()

const formRef = ref(null)
const gatewayRef = ref(null)
const form = ref({
  dollar: 100,
  type: '支付宝',
  agree: false,
  accountID: ''
})

const width = ref(window.innerWidth)
const rules = {
  dollar: [
    {required: true, message: '请输入充值金额', trigger: 'blur'},
    {
      pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
      message: '请输入正确的充值金额，最多保留2位小数',
      trigger: 'blur'
    }
  ],
  accountID: [{required: true, message: '请输入6位数字的账户ID', trigger: 'blur'}]
}

const dialogType = ref('')
const dialogContentType = ref('')
const dialogContent = ref({})

const balance = ref('--')
const realType = ref(false)

const confirmData = ref({})

const {initConfirmData} = init()

const getRechargePay = async () => {
  const res = await api.specialPay(form.value.type, form.value.dollar, form.value.accountID)
  confirmData.value = initConfirmData(form.value.type, res)
}

const goPay = async () => {
  const result = await formRef.value.validate()
  if (true !== result) return
  if (realType.value) return dialogType.value = 'verify'
  if (['支付宝', '微信'].indexOf(form.value.type) >= 0) {
    await getRechargePay()
    dialogType.value = 'confirm'
    return
  }

  if ('银联' === form.value.type) {
    await getRechargePay()
    return proxy.$router.push({
      name: 'paymentCheckout',
      params: {type: 'union', id: confirmData.value.recharge_no}
    })
  }

  if ('网关' === form.value.type) {
    await getRechargePay()
    return gatewayRef.value.submit()
  }
}

const goToQbit = () => {
  window.open('https://www.qbitnetwork.com/#/landing-page', '_blank')
}

const getRechargeInfo = async () => {
  const res = await api.getSpecialRecharge()
  realType.value = res.data.real_type
}

onBeforeUnmount(() => {
  ElMessage.closeAll()
})

onMounted(async () => {
  window.onresize = () => {
    width.value = window.innerWidth
  }
  await getRechargeInfo()
})

const handleUpdate = (type, data) => {
  if ('verify' === type) {
    dialogType.value = 'confirm'
    confirmData.value = data
  }
}

</script>

<style lang="less" scoped>
:deep(.payment-radio) {
  padding-bottom: 20px;

  .el-radio.is-bordered {
    height: auto;
  }

  .el-radio__label {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px 0 15px 20px;
  }

  img {
    width: 40px;
    height: 40px;
  }

  .payment-img {
    display: flex;
  }

  .payment-label {
    font-size: 16px;
    margin-left: 10px;
    color: #000000;
  }

  .el-radio__label .el-row {
    width: 100%;
  }

  .el-radio__label .describe {
    padding-right: 0 !important;
  }

  .el-radio__label .describe span {
    white-space: break-spaces;
    font-size: 14px;
    color: #595959 !important;
    font-weight: normal !important;
  }

  .payment-icon {
    font-size: 40px;
    font-weight: 400 !important;
    color: #000;
  }
}

:deep(.agreeTipBorder) {
  border: 1px dashed #f56c6c;
  padding-left: 10px;
}
</style>
<style>

.topTip .el-alert--info.is-light {
  background-color: #e6f7ff !important;
  border: 1px solid #91d5ff !important;
  padding-left: 16px;
  margin-bottom: 20px;
}

.topTip .el-alert--info.is-light .el-alert__icon {
  color: #2b82ff !important;
}

.topTip .el-alert__title.is-bold {
  font-size: 16px;
  font-weight: 400;
  color: #51575a;
}

.qbit-top-banner {
  margin-top: -16px;
  margin-bottom: 16px;
  min-height: 70px;
}

.qbit-top-banner img {
  width: 100%;
  cursor: pointer;
}
</style>
