import {getCurrentInstance} from "vue";
import api from "@/request/api/finance";
import {ElMessage} from "element-plus";

export default function init() {
  const {proxy} = getCurrentInstance();

  const refreshStatus = async (rechargeId) => {
    const res = await api.setRecharge(rechargeId)
    if (res.code == 200) {
      ElMessage.success('该订单充值成功')
      proxy.$router.push('/recharge_list')
    } else {
      ElMessage.error('该订单充值未成功')
      proxy.$router.push('/recharge')
    }
  }

  const initConfirmData = (type, res) => {
    if (['支付宝', '微信'].indexOf(type) >= 0) return {
      dollar: res.data.dollar,
      handlingFee: res.data.handling_fee,
      amount: res.data.amount,
      totalAmount: res.data.total_amount,
      payId: res.id
    }

    if ('银联' === type) return {
      recharge_no: res.recharge_no
    }

    if ('网关' === type) return res.data

    return {}
  }

  return {
    refreshStatus, initConfirmData
  }
}

