<template>
  <el-dialog v-model="dialogShow"
             :title="content.title ? content.title : '标题'"
             :width="'alert' === contentType ? '440px' :'700px'"
             @close="$emit('close', '')"
             :custom-class="contentType">
    <el-row v-if="'alert' === contentType" align="middle">
      <el-col :span="2">
        <i class="el-icon-warning"
           style="color: var(--el-color-warning);font-size: 24px;"/>
      </el-col>
      <el-col class="alert-content" :span="22" v-if="content.content" v-html="content.content"/>
    </el-row>
    <el-form :model="form" label-width="100px" v-else>
      <el-form-item label="付款金额" required>
        <el-input size="small" prefix-icon="iconfont icon-meiyuan" disabled v-model="paymentData.dollar"/>
      </el-form-item>
      <el-form-item label="手续费" required>
        <el-input
            size="small"
            prefix-icon="iconfont icon-meiyuan"
            disabled
            v-model="confirmData.handlingFee"
        ></el-input>
      </el-form-item>
      <el-form-item label="" required>
        <template #label>
          <span v-if="paymentData.type == '连连支付'">应付美元</span>
          <span v-else>应付人民币</span>
        </template>
        <el-input
            size="small"
            :prefix-icon="paymentData.type == '连连支付' ? 'iconfont icon-meiyuan' : 'iconfont icon-renminbi'"
            disabled
            v-model="confirmData.amount"
        ></el-input>
      </el-form-item>
      <el-form-item label="实际到账" required>
        <el-input
            size="small"
            prefix-icon="iconfont icon-meiyuan"
            disabled
            v-model="confirmData.totalAmount"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <template v-if="'alert' === contentType">
        <el-button size="small" @click="dialogShow = false">
          {{ content.cancelText ? content.cancelText : '取 消' }}
        </el-button>
        <el-button size="small" type="primary" @click="content.handleSuccess ? content.handleSuccess() : ''">
          {{ content.confirmText ? content.confirmText : '确 定' }}
        </el-button>
      </template>
      <template v-else>
        <el-button size="small" @click="dialogShow = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submit">确 定</el-button>
      </template>
    </template>
  </el-dialog>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";

const dialogShow = ref(true)
const props = defineProps({
  paymentData: {type: Object},
  confirmData: {type: Object},
  dialogType: {default: ''},
  dialogContent: {type: Object}
})

const {proxy} = getCurrentInstance()
const form = ref({
  type: props.type,
})

const contentType = ref(props.dialogType ? props.dialogType : '')
const content = ref(Object.assign({title: '付款确认'}, props.dialogContent ? props.dialogContent : {}))

const submit = async () => {
  if (['支付宝','微信'].indexOf(props.paymentData.type) >= 0) {
    return proxy.$router.push({
      name: 'payment',
      query: {payid: props.confirmData.payId}
    })
  }
}

</script>

<style>
.el-dialog.alert .el-dialog__body {
  padding: 10px 20px !important;
}

.alert-content {
  line-height: 24px;
  font-size: 14px;
}
</style>